<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="21" stroke="#363636" stroke-width="2" />
    <path
      d="M24 16.0547H18.4027C16.8391 16.0488 14.0867 16.5556 14.1599 19.3873C14.2318 22.169 17.4729 22.571 18.8039 21.8565C20.3828 21.0089 20.4823 17.8464 18.3235 17.6967C15.8157 17.5228 16.0011 19.9854 17.0864 20.2287C18.0843 20.4525 18.514 19.471 17.8691 19.1961"
      stroke="#363636"
      stroke-linecap="round"
    />
    <path
      d="M24 16.0547H29.5973C31.1609 16.0488 33.9133 16.5556 33.8401 19.3873C33.7682 22.169 30.5271 22.571 29.1961 21.8565C27.6172 21.0089 27.5177 17.8464 29.6765 17.6967C32.1843 17.5228 31.9989 19.9854 30.9136 20.2287C29.9157 20.4525 29.486 19.471 30.1309 19.1961"
      stroke="#363636"
      stroke-linecap="round"
    />
    <path
      d="M18.7356 23.6302V30.2178M21.329 21.8129V31.3536M24.017 20.5545V31.9397M26.6483 21.8129V31.3536M29.2416 23.6302V30.2178M21.5229 19.0078H26.4233"
      stroke="#363636"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'TheHistoricalSiteIcon',
};
</script>
